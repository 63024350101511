export const CustomerCards1 = [
    {
        id:'berscia-img',
        src: 'https://iaterial.sirv.com/Olympus/c38ca177-39eb-4681-9b89-0fc82827b9fa.jfif',
        alt: ' BERSCIA ME logo',
        name: 'BERSCIA Middle East',
        link: 'http://www.bme.com.lb/',
    },
    {
        id:'iel-img',
        src: 'https://iaterial.sirv.com/Olympus/intereconlobby%20logo.png',
        alt: 'International Economic Lobby',
        name: 'International Economic Lobby',
        link: 'https://internationaleconomiclobby.com/en/home',
    },
    {
        id:'leb-file-img',
        src: 'https://iaterial.sirv.com/Olympus/lebanon-files-1.png?w=500&h=500',
        alt: 'Lebanon Files logo',
        name: 'Lebanon Files',
        link: 'https://www.lebanonfiles.com/',
    },
    {
        id:'leb-on-img',
        src: 'https://iaterial.sirv.com/Olympus/lebanonON.png',
        alt: 'LebanonOn logo',
        name: 'LebanonON',
        link : 'https://www.lebanonon.com/',
    },
    {
        id:'dia-on-img',
        src: 'https://iaterial.sirv.com/Olympus/logo2020.png',
        alt: 'DiasporaON logo',
        name: 'DiasporaON',
        link : 'https://www.diasporaon.com/',
    },
    {       
        id:'leb-deb-img',
        src: 'https://iaterial.sirv.com/Olympus/logolebanon%20debate.png',
        alt: 'Lebanon Debate logo',
        name: 'Lebanon Debate',
        link : 'https://www.lebanondebate.com/',
    },
    {       
        id:'mic-img',
        src: 'https://iaterial.sirv.com/Olympus/microsoft%20log.png',
        alt: 'MSRC',
        name: 'Microsoft security response center bounty program',
        link : 'https://www.microsoft.com/en-us/msrc',
    },
    {       
        id:'tek-img',
        src: 'https://iaterial.sirv.com/Olympus/Tek-logo-01.png',
        alt: 'Teknologiia logo',
        name: 'Teknologiia',
        link: 'https://teknologiia.com/',
    },
    {
        id:'vdl-img',
        src: 'https://iaterial.sirv.com/Olympus/VDLlogo.png',
        alt: 'VDL logo',
        name: 'Voix Du Liban', 
        link: 'https://www.vdlnews.com/',
    },
    {
        id:'we-store-img',
        src: 'https://iaterial.sirv.com/Olympus/weStores%20logo.png',
        alt: 'WE Stores logo',
        name: 'We Stores', 
        link: 'https://westores.online/',
    },
    {
        id:'gom-img',
        src: 'https://iaterial.sirv.com/Olympus/GOM%20logo.png',
        alt: 'GOM LOGO',
        name: 'GOD OF MINING', 
        link: 'https://www.godofmining.net/',
    },
];